<template>
  <div class="comment">
     <!-- 头部 -->
    <van-nav-bar title="评论详情" left-text="返回" left-arrow @click-left="onClickLeft" />

    <div class="item" v-for="(item,index) in commentList" :key="index" >
      <div class="item-header">
      
         <van-image
                      round
                      cover
                      fit="cover"
                      @click.stop="jumppersonal(item.userId)"
                      :src="item.userHeadImg?item.userHeadImg :'https://i.loli.net/2021/11/03/bDNUWZFYLO76kjJ.png'"
                    />
        <p>{{item.nickName}}</p>
        <p>{{item.createTime}}</p>
        <van-rate v-model="item.score" :size="10" readonly color="#ffd21e" void-icon="star" void-color="#eee"/>
      </div>
      <div class="item-content">
        <div class="txt">
            {{item.content}}
        </div>
        <div class="imgpic" >
            <img :src="val" alt="" v-for="(val,index) in item.evaluateImg" :key="index" @click="sceneImg(item.evaluateImg,index)">
        </div>
      </div>
      <div class="item-footer">
        <div class="right">
            <span @click.stop="Report(item)">举报</span>
            <span @click="parise(item)"><span :class="item.endorse==false?'hui':'red'"></span>赞({{item.countPraise}})</span>
            <span><span></span>回复({{alltotal}})</span>
        </div>
      </div>


    <div class="line"></div>
    <div class="kong-comment" v-if="show==true">
       <p>{{alltotal}}条回复</p>
       <div class="text">暂无人回复该条评论，赶快来抢沙发吧</div>
    </div>

    <div class="son-content" v-if="show1==false">
       <p>{{alltotal}}条回复</p>
       <ul class="content-ul">
         <li class="content-item clearfix" v-for="(son,index) in item.childEvaluate" :key="index">
           <div class="header">
             <div class="image"  @click.stop="jumppersonal(son.userId)">
                  <van-image
                        round
                        cover
                        fit="cover"
                        :src="son.userHeadImg?son.userHeadImg :'https://i.loli.net/2021/11/03/bDNUWZFYLO76kjJ.png'"
                      />
            </div>
             <div class="nickName">
               <p class="title">{{son.nickName ? son.nickName:`&nbsp;&nbsp;`}}</p>
               <p class="time">{{son.createTime}}</p>
             </div>
            <p class="icon">
              <span @click="Report(son)"><van-icon name="warning-o"  size="15px"/></span>
              <span @click="parise(son)" :class="son.endorse==false?'fonthui':'fontred'">{{son.countPraise}}<span :class="son.endorse==false?'hui':'red'"></span></span>
            </p>
           </div>

           <p class="text-content">{{son.content}}</p>
           
           <div class="fine-line"></div>
         </li>
       </ul>
    </div>
  </div>
    <div style="height:1rem"></div>

    <van-search v-model="value" placeholder="写条评论吧~" @search="onsubmit" >
      <template #left-icon>
         <img src="../assets/image/comment-pen.png" alt="">
      </template>
    </van-search>
  </div>
</template>

<script>
import Vue from 'vue';
import { Search } from 'vant';
import { Rate } from 'vant';
import { ImagePreview } from 'vant';

Vue.use(Rate);
Vue.use(Search);
import { getcomment,addcomment,pariseadmin } from "@api/servicemall.js";
import qs from "qs";
  export default {
    data(){
      return{
         item:'',
         value:'',
         productId:'',
         evaluateId:'',
         commentPic:[],       //用户图片数组
         commentList:[],
         paramsList:[],
         alltotal:'',
         show:'',
         show1:'',
      }
    },
    methods:{
      jumppersonal(id){
        this.$router.push({
          name:"Userpersonal",
          query:{
            id:id
          }
        })
      },
       //  返回
      onClickLeft() {
         this.$router.go(-1)
      },
       //举报
      Report(item){
         this.$router.push({
          name:"complaint",
          query:{
            id:item.evaluateId,
            type:6
          }
        })
      },
      //点赞
      async parise(item){ 
        const data = {
          praiseType:1,
          targetId:item.evaluateId
        }
        const result = await pariseadmin(data)
        if(result.code === 0){
          console.log(result.data)
          if(result.data){
            this.$toast("亲，点赞成功啦！")
          }else{
            this.$toast("亲，取消点赞噢！")
          }
           this.getcomments()
        }
      },
      //添加评论
      async onsubmit(value){
        const evaluates = {
          content:value,
          evaluateImg:'',
          orderId:'',
          score:'',
          evaluateParentId:this.evaluateId,
          productId:this.productId,
        }
        this.paramsList = []
        this.paramsList.push(evaluates)
        const result = await addcomment(this.paramsList)
        if(result.code === 0 ){
          this.$toast("评论成功")
          this.getcomments()
          this.value = "",
          this.$router.go(0)
        }
      },
       // 图片预览
      sceneImg(images,index) {
        ImagePreview({
          images:images, //需要预览的图片 URL 数组
          showIndex:true, //是否显示页码
          loop:false, //是否开启循环播放
          startPosition:index //图片预览起始位置索引
        })
      },
      //获取用户评论
      async getcomments(){
         const data = {
           productId:this.productId,
           evaluateId:this.evaluateId
         }
         const result = await getcomment(qs.stringify(data))
         if(result.code === 0 ){
           this.commentList = result.data
           this.commentList.map(item=>{
             if(item.childEvaluate.length == 0 ){
               this.show = true
               this.show1 = true
               this.alltotal = item.childEvaluate.length
             }else{
               this.show = false
               this.show1 = false
               this.alltotal = item.childEvaluate.length
             }
             if(typeof item.evaluateImg == "undefined" || item.evaluateImg == null || item.evaluateImg == ""){
               return ;
             }else{
                 this.commentPic = item.evaluateImg.split(',')
                 delete item.evaluateImg;
                 item.evaluateImg = this.commentPic
             }
           })
         }
      }
    },
    created(){
      this.productId = this.$route.query.productId
      this.evaluateId = this.$route.query.evaluateId
      this.getcomments()
    }
  }
</script>

<style lang="scss" scoped>
.clearfix::after{
  clear: both;
  content: "";
  display: block;
}
.comment{
  ::v-deep .van-nav-bar__text{
      color: #333333;
      font-size: 15px;
      font-weight: 500;
    }
     ::v-deep .van-nav-bar .van-icon{
      color: #333333;
    }
    ::v-deep .van-nav-bar__title{
      font-size: 18px;
      font-weight: bold;
      color: #333333;
    }
  .item-header{
          height: 33px;
          margin-left: 20px;
          margin-top: 25px;
          position: relative;
          img{
            width: 33px;
            height: 33px;
            border-radius: 50%;
            position: absolute;
          }
          p:first-of-type{
            position: absolute;
            font-size: 13px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #4E4E4E;
            left: 43px;
          }
          p:last-of-type{
            position: absolute;
            font-size: 12px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #999999;
            right: 19px;
          }
          ::v-deep .van-rate{
            position: absolute;
            left: 43px;
            top: 20px;
          }
        }
        .item-content{
          margin-top: 14px;
          margin-left: 20px;
          .txt{
            font-size: 13px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #626262;
          }
          .imgpic{
            margin-top: 15px;
            img{
              width: 107px;
              height: 107px;
              margin-left: 10px;
            }
            img:first-of-type{
              margin-left: 0;
            }
          }
        }
        .item-footer{
          position: relative;
          font-size: 12px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #888888;
          margin-top: 15px;
          height: 20px;
          .right {
            position: absolute;
            right: 19px;
            >span{
              margin-left: 10px;
            }
            >span:first-of-type{
                  .red{
                    width: 15px;
                    height: 15px;
                    margin-right: 5px;
                    background: url('../assets/image/red-zan.png') no-repeat;
                    background-size: 100% 100%;
                    display: inline-block;
                    transform: translateY(2px);
                  }
                  .hui{
                    width: 15px;
                    height: 15px;
                     margin-right: 5px;
                    background: url('../assets/image/comment-dianzan.png') no-repeat;
                    background-size: 100% 100%;
                    display: inline-block;
                    transform: translateY(2px);
                  }  
            }
            >span:last-of-type{
               span:first-of-type{
                display: inline-block;
                width: 16px;
                height: 16px;
                background: url("../assets/image/comment-pinglun-yellow.png") no-repeat;
                background-size: 100% 100%;
                margin-right: 8px;
                transform: translateY(2px);
              }
            }
          }
        }
    .line{
      width: 100%;
      height: 10px;
      background: #F8F8F8;
      margin-top: 12px;
    }
    .kong-comment{
      height: 200px;
      position: relative;
      p{
        margin-top: 10px;
        margin-left: 20px;
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #888888;
      }
      .text{
        width: 204px;
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #CCCCCC;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
      }
    }
    .son-content{
      padding-bottom: 20px;
      >p{
        margin-top: 10px;
        margin-left: 20px;
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #888888;
      }
      .content-item{
        margin-top: 20px;
        margin-left: 22px;
        margin-right: 22px;
        .header{
          display: flex;
        }
        .nickName{
          width: 215px;
          margin-left: 10px;
        }
        .image{
           width:34px;
           height: 34px;
           img{
             width: 100%;
             height: 100%;
             border-radius: 50%;
           }
        }
        .title{
          font-size: 12px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #4E4E4E;
        }
        .time{
          font-size: 12px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #999999;
        }
        .text-content{
          font-size: 13px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #4E4E4E;
          margin-top: 10px;
        }
        .icon{
          font-size: 10px;
          font-family: PingFang SC;
          color: #A7A7A7;
          >span:last-of-type{
            margin-left: 20px;
            transform: translateY(-2px);
            span{
              margin-left: 5px;
            }
          }
          span{
            display: inline-block; 
             .red{
                width: 15px;
                height: 15px;
                background: url('../assets/image/red-zan.png') no-repeat;
                background-size: 100% 100%;
              }
              .hui{
                width: 15px;
                height: 15px;
                background: url('../assets/image/comment-dianzan.png') no-repeat;
                background-size: 100% 100%;
              }       
          } 
          .fontred{
            color:#EB441E;
          }  
          .fonthui{
             color: #A7A7A7;
          }
         
        }
        .fine-line{
          width: 325px;
          height: 1px;
          background: #E1E1E1;
          margin-top: 20px;
        }
      }
    }
    ::v-deep .van-field__left-icon{
      margin-top:4px;
      img{
        width: 18px;
        height: 18px;
      }
    }
    ::v-deep .van-search__content{
      border-radius: 18px;
      background: #EFEFEF;
    }
    ::v-deep .van-search{
      position: fixed;
      width: 100%;
      bottom: 0px;
    }
} 
::v-deep .van-image--round{
    width: 33px;
    height: 33px;
     position: absolute;
}
</style>